/* eslint-disable */
import React, { Component } from 'react';
import { Route, BrowserRouter as Router, Switch, Redirect } from 'react-router-dom';
import './App.css';
import HomePage from './components/Website/app';

class App extends Component {
  render() {
    return (
      <Router>
        <Switch>
          <Route exact path="/" component={()=>{
            return <Redirect exact from="/" to="/home" />
          }} />
          <Route path="/home" component={HomePage} />
        </Switch>

      </Router>
    );
  }
}

export default App;
