import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import GridContainer from "../../../utils/Grid/GridContainer.js";
import GridItem from "../../../utils/Grid/GridItem.js";
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles({
    section: {
        padding: "40px 0",
        // textAlign: "center",
        color: "black"
    },
    title: {
        fontSize: "20px",
        color: "black",
        left: 0
    },
    content: {
        fontSize: "2.5vh",
        color: "black"
    }
})


export default function Donate(props) {
    const classes = useStyles();
    return (
        <>
            <div className={classes.section}>
                
            </div>
        </>
    )
}