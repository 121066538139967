
import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import ButtonBase from '@material-ui/core/ButtonBase';
import Container from '@material-ui/core/Container';
import Typography from '../../../../utils/assets/jss/material-kit-react/components/typography';
import styles from './style';

function SexualHarass(props) {
  const { classes } = props;

  const images = [
    
    {
      url:
        'https://i.ibb.co/hD2kCct/Sexual-Harrasment-workshop-in-Rohit-Enterprises-Bawana-on-10-12-18-5.jpg',
      title: 'Workshop in Rohit Enterproces',
      width: '60%',
    },
    {
      url:
        'https://i.ibb.co/D8SjPPm/Sexual-Harrasment-workshop-in-Omaxe-Industries-Bawana-on-10-10-18-2.jpg',
      title: 'Workshop in Omaxe industries',
      width: '40%',
    },
    {
      url:
        'https://i.ibb.co/xFZXvQF/Sexual-Harrasment-workshop-in-Mahalakshmi-Plastics-Bawana-on-9-11-18-4.jpg',
      title: 'Workshop in Mahalaxmi store',
      width: '40%',
    },
    {
        url:
          'https://i.ibb.co/2SK2QFN/Sexual-Harrasment-workshop-in-Agarwal-plastic-Bawana-on-7-10-18-2.jpg',
        title: 'Workshop in agarwal store',
        width: '60%',
      },
      
    
  ];
 
  
  return (
    <Container className={classes.root} component="section">
       

       
      <Typography variant="h4" marked="center" align="center" component="h4">
      </Typography>
      <div className={classes.images}>
        {images.map((image) => (
          <ButtonBase
            key={image.title}
            className={classes.imageWrapper}
            style={{
              width: image.width,
            }}
          >
            <div
              className={classes.imageSrc}
              style={{
                backgroundImage: `url(${image.url})`,
              }}
            />
            <div className={classes.imageBackdrop} />
            <div className={classes.imageButton}>
              <Typography
                component="h3"
                variant="h6"
                color="inherit"
                className={classes.imageTitle}
              >
                {image.title}
                <div className={classes.imageMarked} />
              </Typography>
            </div>
          </ButtonBase>
        ))}
      </div>
    </Container>
  );
}


SexualHarass.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SexualHarass);
