
import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import ButtonBase from '@material-ui/core/ButtonBase';
import Container from '@material-ui/core/Container';
import Typography from '../../../../utils/assets/jss/material-kit-react/components/typography';
import styles from './style';

function Health(props) {
  const { classes } = props;

  const images = [

    {
      url:
        'https://i.ibb.co/K93ZRXK/Anti-Tobacco-Program-min.jpg',
      title: 'It is health that is real wealth and not pieces of gold and silver.',
      width: '30%',
    },
    {
      url:
        'https://i.ibb.co/0KT0VmV/20181130-113534-min.jpg',
      title: 'He who has health has hope',
      width: '40%',
    },
    {
      url:
        'https://i.ibb.co/FzZ8BN9/20181130-113506-min.jpg',
      title: 'A life without health is like a river without water.',
      width: '30%',
    },
    {
      url:
        'https://i.ibb.co/jkqS64k/20181130-113501-min.jpg',
      title: 'A fit body, a calm mind, a house full of love.',
      width: '55%',
    },
    {
      url:
        'https://i.ibb.co/W60Ggwz/430083-284758061597017-1076184207-n-min.jpg',
      title: 'Happiness is the highest form of health.',
      width: '45%',
    },
  ];

  return (
    <Container className={classes.root} component="section">
      <Typography variant="h4" marked="center" align="center" component="h4">
      </Typography>
      <div className={classes.images}>
        {images.map((image) => (
          <ButtonBase
            key={image.title}
            className={classes.imageWrapper}
            style={{
              width: image.width,
            }}
          >
            <div
              className={classes.imageSrc}
              style={{
                backgroundImage: `url(${image.url})`,
              }}
            />
            <div className={classes.imageBackdrop} />
            <div className={classes.imageButton}>
              <Typography
                component="h3"
                variant="h6"
                color="inherit"
                className={classes.imageTitle}
              >
                {image.title}
                <div className={classes.imageMarked} />
              </Typography>
            </div>
          </ButtonBase>
        ))}
      </div>
    </Container>
  );
}


Health.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Health);
