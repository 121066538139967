import React from "react";
// @material-ui/core components
import classNames from "classnames";
// @material-ui/icons
import { title } from "../../../utils/assets/jss/material-kit-react.js"
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import EcoOutlinedIcon from '@material-ui/icons/EcoOutlined';
import EmojiObjectsOutlinedIcon from '@material-ui/icons/EmojiObjectsOutlined';
import PetsOutlinedIcon from '@material-ui/icons/PetsOutlined';
// import ThumbsUpDownOutlinedIcon from '@material-ui/icons/ThumbsUpDownOutlined';
// import WcOutlinedIcon from '@material-ui/icons/WcOutlined';
// import Typography from '../../../utils/assets/jss/material-kit-react/components/typography';


// core components
import GridContainer from "../../../utils/Grid/GridContainer.js";
import GridItem from "../../../utils/Grid/GridItem.js";
import InfoArea from "../../../utils/InfoArea/InfoArea.js";

// import styles from "../../../utils/assets/jss/material-kit-react/views/landingPageSections/productStyle.js";

// const useStyles = makeStyles(styles);


const styles = (theme) => ({
  section: {
    padding: "70px 0",
    textAlign: "center"
  },
  title: {
    ...title,
    marginBottom: "1rem",
    marginTop: "30px",
    minHeight: "32px",
    textDecoration: "none"
  },
  description: {
    color: "rgb(108, 117, 125)"

  },
  subtitle: {
    fontSize: "1.313rem",
    maxWidth: "500px",
    margin: "10px auto 0"
  },
  main: {
    background: "#FFFFFF",
    position: "relative",
    zIndex: "3"
    
  },
  mainRaised: {
    padding:"10px 10px 10px 0px",
    margin: "-60px 1.9vw 0px",
    borderRadius: "6px",
    boxShadow:
      "0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)",
    marginTop: "20px",
    marginLeft:"5px"

  },
  pading: {
    paddingLeft:"10px",
    paddingRight: "10px"
  },
  box: {
    [theme.breakpoints.down('sm')]: {
      width: '90% !important',
      
      marginLeft:'2%',
      marginRight:'8%'
    }
    
    
  }
});

function ProductSection(props) {
  const { classes } = props;

  return (
    // <div className={classNames(classes.main, classes.mainRaised)}>

    <div className={classes.section}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={8}>
          <h2 className={classNames(classes.description,classes.box)}>
            Open hearts. Open minds. Open doors</h2>
          <h5 className={classNames(classes.description,classes.box)}>
          "Our mission is to enhance the quality of education by providing financial assistance to deserving students, raising operating funds for member colleges and universities, and increasing access to technology for students and faculty at historically black colleges and universities."
            
          </h5>
        </GridItem>
      </GridContainer>
      <div>
        <GridContainer>

          <GridItem s={12} sm={12} md={4} className={classes.box}>
          <div className={classNames(classes.main, classes.mainRaised,classes.pading)}>

            <InfoArea
           
              title="MISSION"
              icon={EcoOutlinedIcon}
              iconColor="info"
              vertical
            />
          
            <h5 className={classes.description}>
            To readicate hunger and poverty.
            </h5>
            </div>  
          </GridItem>
          
          <GridItem xs={12} sm={12} md={4} className={classes.box}>
          <div className={classNames(classes.main, classes.mainRaised)}>

            <InfoArea
              title="VISION"
              icon={EmojiObjectsOutlinedIcon}
              iconColor="success"
              vertical
            />
             <h5 className={classes.description}>
              world full of people devoid of miseries.
            </h5>
            </div>
          </GridItem>

          <GridItem xs={12} sm={12} md={4} className={classes.box} >
          <div className={classNames(classes.main, classes.mainRaised)}>

            <InfoArea
              style
              title="MOTO"
              icon={PetsOutlinedIcon}
              iconColor="info"
              vertical
            />
             <h5 className={classes.description}>
             we rise by lifting other.
            </h5>
          </div>
          </GridItem>
        </GridContainer>
      </div>
    </div>
   
  );
}

ProductSection.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ProductSection);
