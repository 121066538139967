import React from "react";
import "../../../../App.css"
import { container, title } from "../../../utils/assets/jss/material-kit-react.js"
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
// core components
import GridItem from "../../../utils/Grid/GridItem.js";
import WomenRights from './gallery/womenemporement.js';
import Sanitization from './gallery/sanitization.js';
import SexualHarass from './gallery/sexualh.js';
import Yoga from './gallery/yoga.js'
import Livehood from './gallery/livehood.js'
import Health from './gallery/health'
// Sections for this page

const styles = (theme) => ({
  container: {
    zIndex: "12",
    color: "#FFFFFF",
    ...container
  },
  title: {
    ...title,
    display: "inline-block",
    position: "relative",
    marginTop: "30px",
    minHeight: "32px",
    color: "#fff",
    textDecoration: "none",
    fontFamily: "Montserrat"

  },
  subtitle: {
    fontSize: "1.313rem",
    maxWidth: "500px",
    margin: "10px auto 0"
  },
  main: {
    background: "#FFFFFF",
    position: "relative",
    zIndex: "3"
  },
  mainRaised: {
    margin: "-60px 30px 0px",
    borderRadius: "6px",
    boxShadow:
      "0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)"
  },
  paperclips: {
    display: "flex",
    marginTop: "-84px",
    justifyContent: "center !important"
  },
  description: {
    color: "#999",
    textAlign: "center",
    fontSize: "0.9rem",
    fontFamily: "Montserrat",
    [theme.breakpoints.down('sm')]: {
      width: '90% !important',

      marginLeft: '2%',
      marginRight: '8%'
    }

  },
  boxin: {


    [theme.breakpoints.down('sm')]: {
      width: '90% !important',

      marginLeft: '2%',
      marginRight: '8%'
    }

  },
});
function Gallery(props) {
  const { classes } = props;

  return (
    <>
      <div>
        <GridItem style={{ textAlign: 'center' }}>
          <br />
          <h1 className={classes.title + classes.boxin} style={{ color: "#6c757d" }}>Mahila Panchayat Programme </h1>
        </GridItem>
        <h5 className={classes.description} >
          Samkalp is awarded Mahila Panchayat Program under Police Station Vijay Vihar by Delhi Commission of Women (DCW) to understand, and resolve the matters related to women in the area of PS Vijay Vihar. Community meetings are held for the same. Door to door awareness campaign is launched. Also, the project is integrated with local Vijay Vihar police station and DCW.
        </h5>
        <WomenRights />
      </div>


      <div className={classes.container}>
        <GridItem style={{ textAlign: 'center' }}>
          <br />
          <h1 className={classes.title + classes.boxin} style={{ color: "#6c757d" }}>Sanitization Programme </h1>
        </GridItem>
        <h5 className={classes.description} >
          Sanitation programs in the neighborhood areas are regularly conducted. Swatchhtha (Cleanliness) is basic necessity for healthy children and adults. Cleaning campaign in bus depots, parks, other areas are being conducted from time to time.        </h5>
        <Sanitization />
      </div>

      <div className={classes.container}>
        <GridItem style={{ textAlign: 'center' }}>
          <br />
          <h1 className={classes.title + classes.boxin} style={{ color: "#6c757d" }}>Sexual harrasment</h1>
        </GridItem>
        <h5 className={classes.description} >
          Sexual harassment is unwanted sexual conduct that is humiliating, intimidating or offensive. It may be verbal or physical, and can occur in direct way (face to face) or indirect way(online). Both men and women can be affected by this unethical action. 
          <br />
          In rural area most of the cases occured due to lack of awareness of laws provided by the government to reduce such crimes. We have conducted various campaign to spread the awareness among people.      </h5>
        <SexualHarass />
      </div>

      <div className={classes.container}>
        <GridItem style={{ textAlign: 'center' }}>
          <br />
          <h1 className={classes.title + classes.boxin} style={{ color: "#6c757d" }}>Yoga training Programme</h1>
        </GridItem>
        <h5 className={classes.description} >
          Yoga is the only science that teaches prevention instead of corrections. Arogya and not Rog. Yoga is now accepted by the UN and world over as a pure form of staying away from diseases. Regular yoga classes are and special ones on yoga days are observed.
        </h5>
        <Yoga />
      </div>

      <div className={classes.container}>
        <GridItem style={{ textAlign: 'center' }}>
          <br />
          <h1 className={classes.title + classes.boxin} style={{ color: "#6c757d" }}>Livelihood</h1>
        </GridItem>
        <h5 className={classes.description} >
          Livelihood is the basic necessity for anybody. It is often said that no useful work can be done if the stomach is empty. The farmers and common public from the poor section is educated on how to increase income. Various schemes available with the government sector are also informed to them.
         </h5>
        <Livehood />
      </div>

      <div className={classes.container}>
        <GridItem style={{ textAlign: 'center' }}>
          <br />
          <h1 className={classes.title + classes.boxin} style={{ color: "#6c757d" }}>Health Awareness Programme</h1>
        </GridItem>
        <h5 className={classes.description} >
          It is always said that it is better to catch them young. Students are given awareness about various medial health related topics over the year. HIV, Tobacco and other problems were discussed which are beneficial for students to know while growing up.         </h5>
        <Health />
      </div>
    </>
  );
}
Gallery.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Gallery);