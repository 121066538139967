import React from "react";
import { Route, Switch, useRouteMatch } from 'react-router-dom';
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons

// core components
import Header from "../../utils/Header/Header.js";
import GridContainer from "../../utils/Grid/GridContainer.js";
import GridItem from "../../utils/Grid/GridItem.js";
// import Button from "../../utils/CustomButtons/Button.js";
import Parallax from "../../utils/Parallax/Parallax.js";
import Footer from "../../utils/Footer/Footer";

import styles from "../../utils/assets/jss/material-kit-react/views/landingPage.js";


//Sections
import Intro from './Sections/introSection';
import Gallery from './Sections/gallery';
import GoverningBody from "./Sections/governingBody.js";
import Donate from "./Sections/donate.js";
import { Typography, Grid } from "@material-ui/core";

const useStyles = makeStyles(styles);
// const useStyles1 = makeStyles(styles1);

const HeaderLinks = [
  { "href": "/home/gallery", "name": "Gallery" },
  { "href": "/home/gov-body", "name": "Governing Body" },
  { "href": "/home/donate", "name": "Donate" }
]

const IntroPage = (props) => {
  return (
    <>
      <Parallax filter image={require("../../utils/assets/img/landingpagepic1.jpg")}>
        <div className={props.classes.container}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={6}>
              <h1 className={props.classes.title}>Your Story Starts With Us.</h1>
              <h4>
                Samkalp Education Foundation was set up in the year 2010.The foundation has been working in several fields of socially significant areas to upgrade the life of common people particularly weaker section of Indian society.
              </h4>
              <br />
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      <div className={classNames(props.classes.main, props.classes.mainRaised)}>
        <div className={props.classes.container}>
          <Intro />
        </div>
      </div>
    </>
  )
}

const GoverningBodyPage = (props) => {
  return (
    <>
      <Parallax filter image={require("../../utils/assets/img/landingpagepic1.jpg")}>
        <div className={props.classes.container}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={6}>
              <h1 className={props.classes.title}>Your Story Starts With Us.</h1>
              <h4>
                Samkalp Education Foundation was set up in the year 2010.The foundation has been working in several fields of socially significant areas to upgrade the life of common people particularly weaker section of Indian society.
              </h4>
              <br />
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      <div className={classNames(props.classes.main, props.classes.mainRaised)}>
        <div className={props.classes.container}>
          <GoverningBody />
        </div>
      </div>
    </>
  )
}

const GalleryPage = (props) => {
  return (
    <>
      <Parallax filter image={require("../../utils/assets/img/landingpagepic1.jpg")}>
        <div className={props.classes.container}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={6}>
              <h1 className={props.classes.title}>Your Story Starts With Us.</h1>
              <h4>
                Samkalp Education Foundation was set up in the year 2010.The foundation has been working in several fields of socially significant areas to upgrade the life of common people particularly weaker section of Indian society.
              </h4>
              <br />
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      <div className={classNames(props.classes.main, props.classes.mainRaised)}>
        <div className={props.classes.container}>
          <Gallery />
        </div>
      </div>
    </>
  )
}

const DonatePage = (props) => {
  return (
    <>
      <Parallax filter image={require("../../utils/assets/img/landingpagepic1.jpg")}>
        <div className={props.classes.container}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={6}>
              <h1 className={props.classes.title}>Your Story Starts With Us.</h1>
              <h4>
                Samkalp Education Foundation was set up in the year 2010.The foundation has been working in several fields of socially significant areas to upgrade the life of common people particularly weaker section of Indian society.
              </h4>
              <br />
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      <div className={classNames(props.classes.main, props.classes.mainRaised)}>
        <div className={props.classes.container}>
          <Donate />
        </div>
      </div>
    </>
  )
}

const errStyle = {
  button: {
    margin:"200px"
  }
}

const Error = (props) => {
  const classes = makeStyles(errStyle);

  return (
    <>
      <Parallax filter image={require("../../utils/assets/img/landingpagepic1.jpg")}>
        <div className={props.classes.container}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={6}>
              <h1 className={props.classes.title}>Your Story Starts With Us.</h1>
              <h4>
                Samkalp Education Foundation was set up in the year 2010.The foundation has been working in several fields of socially significant areas to upgrade the life of common people particularly weaker section of Indian society.
              </h4>
              <br />
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      <div className={classNames(props.classes.main, props.classes.mainRaised)}>
        <div className={classes.container}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={6}>
              <img src={require('../../utils/assets/img/error_404.jpg')} />
            </GridItem>
            <GridItem xs={12} sm={12} md={6}>
              <div className={classes.button}>
                Oops. You accidently came to the wrong place
              </div>
            </GridItem>
          </GridContainer>
        </div>
      </div>
    </>
  )
}

export default function LandingPage(props) {
  const classes = useStyles();
  const { ...rest } = props;
  const { path, url } = useRouteMatch();
  console.log(url)
  return (
    <div>
      <Header
        color="transparent"
        brand="Samkalp Foundation "
        rightLinks={HeaderLinks}
        fixed
        changeColorOnScroll={{
          height: 400,
          color: "white"
        }}
        {...rest}
      />
      <Switch>
        <Route exact path={url} component={() => {
          return <IntroPage classes={classes} />
        }} />
        <Route path={`${url}/gallery`} component={() => {
          return <GalleryPage classes={classes} />
        }} />
        <Route path={`${url}/gov-body`} component={() => {
          return <GoverningBodyPage classes={classes} />
        }} />
        <Route path={`${url}/donate`} component={() => {
          return <DonatePage classes={classes} />
        }} />
        <Route path={`${url}/*`} component={() => {
          return <Error classes={classes} />
        }} />
      </Switch>
      <Footer />
    </div>
  );
}
