const styles = (theme) => ({
    root: {
      marginTop: theme.spacing(8),
      marginBottom: theme.spacing(4),
    },
    images: {
      marginTop: theme.spacing(8),
      display: 'flex',
      flexWrap: 'wrap',
      margin: '0.2%'
    },
    imageWrapper: {
      position: 'relative',
      display: 'block',
  
      padding: 0,
      border: '2px solid white',
  
      height: '40vh',
      zIndex: '13',
  
      [theme.breakpoints.down('sm')]: {
        width: '90% !important',
        height: 200,
        marginLeft: '2%',
        marginRight: '8%'
      },
      '&:hover': {
        zIndex: 1,
      },
      '&:hover $imageBackdrop': {
        opacity: 0.15,
      },
      '&:hover $imageMarked': {
        opacity: 0,
      },
      '&:hover $imageTitle': {
        border: '0.2px solid white',
        visibility: 'visible',
  
      },
    },
    imageButton: {
      position: 'absolute',
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      color: theme.palette.common.white,
    },
    imageSrc: {
      position: 'absolute',
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      backgroundSize: 'cover',
      backgroundPosition: 'center 40%',
    },
    imageBackdrop: {
      position: 'absolute',
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      background: theme.palette.common.black,
      opacity: 0.5,
      transition: theme.transitions.create('opacity'),
    },
    imageTitle: {
      position: 'relative',
      margin: "10px",
      padding: `${theme.spacing(2)}px ${theme.spacing(4)}px 14px`,
      visibility: 'hidden',
      '&:hover': {
        visibility: 'visible',
        color: "black",
        background: "white",
        transitionDelay: "300ms",
        transitionDuration: "300ms",
        transitionTimingFunction: "ease-in-out"
      },
    },
    imageMarked: {
      height: 3,
      width: 18,
      background: theme.palette.common.white,
      position: 'absolute',
      bottom: -2,
      // left: 'calc(50% - 9px)',
      transition: theme.transitions.create('opacity'),
    },
  });

  export default styles;