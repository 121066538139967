import React from "react";
// nodejs library that concatenates classes
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// import "../../../App.css"

// core components
import GridItem from "../../../utils/Grid/GridItem.js";
import Button from "../../../utils/CustomButtons/Button.js";

import styles from "../../../utils/assets/jss/material-kit-react/views/landingPage.js";


// Sections for this page

import ProductCategories from "./showcase.js";
import ProductSection from "./ProductSection";

const useStyles = makeStyles(styles);

export default function Intro() {
    const classes = useStyles()
    return (
        <>
            <ProductSection />
            <GridItem style={{ textAlign: 'center' }}>
                <br />
                <h1 className={classes.title} style={{ color: "#6c757d" }}>Field Work</h1>
            </GridItem>
            <ProductCategories />
            <GridItem style={{ textAlign: 'center' }}>
                <Button color="primary" href="/home/gallery" >See More</Button>
            </GridItem>
            {/* <WorkSection /> */}
            <div style={{marginBottom:"20px"}}></div>
        </>
    )
}




