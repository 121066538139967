
import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import ButtonBase from '@material-ui/core/ButtonBase';
import Container from '@material-ui/core/Container';
import Typography from '../../../../utils/assets/jss/material-kit-react/components/typography';
import styles from './style';

function Yoga(props) {
  const { classes } = props;

  const images = [
    
    {
      url:
        'https://i.ibb.co/485Y1f5/IMG-20160621-063721-min.jpg',
      title: 'The yoga pose you avoid the most you need the most.',
      width: '60%',
    },
    {
      url:
        'https://i.ibb.co/Tr58z5P/IMG-20160621-063846-1-min.jpg',
      title: 'Yoga takes you where life exists',
      width: '40%',
    },
    {
      url:
        'https://i.ibb.co/rHCXZKd/IMG-20160621-063846-min.jpg',
      title: 'Yoga Workshop',
      width: '100%',
    },
    
        
      
    
  ];
 
  
  return (
    <Container className={classes.root} component="section">
       

       
      <Typography variant="h4" marked="center" align="center" component="h4">
      </Typography>
      <div className={classes.images}>
        {images.map((image) => (
          <ButtonBase
            key={image.title}
            className={classes.imageWrapper}
            style={{
              width: image.width,
            }}
          >
            <div
              className={classes.imageSrc}
              style={{
                backgroundImage: `url(${image.url})`,
              }}
            />
            <div className={classes.imageBackdrop} />
            <div className={classes.imageButton}>
              <Typography
                component="h3"
                variant="h6"
                color="inherit"
                className={classes.imageTitle}
              >
                {image.title}
                <div className={classes.imageMarked} />
              </Typography>
            </div>
          </ButtonBase>
        ))}
      </div>
    </Container>
  );
}


Yoga.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Yoga);
