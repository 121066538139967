
import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import ButtonBase from '@material-ui/core/ButtonBase';
import Container from '@material-ui/core/Container';
import Typography from '../../../../utils/assets/jss/material-kit-react/components/typography';
import styles from './style';

function Livehood(props) {
  const { classes } = props;

  const images = [
    
    {
      url:
        'https://i.ibb.co/9rbkKC2/venkatesh-ji-seminar-1.jpg',
      title: 'Without a constant livelihood, there will be no constant heart',
      width: '60%',
    },
    {
      url:
        'https://i.ibb.co/TPDcz3x/Swadeshi.jpg',
      title: 'Learning is a livelihood.',
      width: '40%',
    },
    {
      url:
        'https://i.ibb.co/kBgWfQ4/IMG-20180106-WA0003.jpg',
      title: 'Life is important to live life',
      width: '38%',
    },
    {
      url:
        'https://i.ibb.co/djrZkPN/IMG-20171212-WA0056.jpg',
      title: 'use power of life',
      width: '24%',
    },
    {
      url:
        'https://i.ibb.co/FbHKyr2/IMG-20171207-WA0050.jpg',
      title: 'Workshop on livelihood',
      width: '38%',
    },
    
        
      
    
  ];
 
  
  return (
    <Container className={classes.root} component="section">
       

       
      <Typography variant="h4" marked="center" align="center" component="h4">
      </Typography>
      <div className={classes.images}>
        {images.map((image) => (
          <ButtonBase
            key={image.title}
            className={classes.imageWrapper}
            style={{
              width: image.width,
            }}
          >
            <div
              className={classes.imageSrc}
              style={{
                backgroundImage: `url(${image.url})`,
              }}
            />
            <div className={classes.imageBackdrop} />
            <div className={classes.imageButton}>
              <Typography
                component="h3"
                variant="h6"
                color="inherit"
                className={classes.imageTitle}
              >
                {image.title}
                <div className={classes.imageMarked} />
              </Typography>
            </div>
          </ButtonBase>
        ))}
      </div>
    </Container>
  );
}


Livehood.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Livehood);
