import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import GridContainer from "../../../utils/Grid/GridContainer.js";
import GridItem from "../../../utils/Grid/GridItem.js";
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles({
    section: {
        padding: "40px 0",
        // textAlign: "center",
        color: "black"
    },
    title: {
        fontSize: "20px",
        color: "black",
        left: 0
    },
    content: {
        fontSize: "2.5vh",
        color: "black"
    }
})


export default function GoverningBody(props) {
    const classes = useStyles();
    return (
        <>
            <div className={classes.section}>
                <GridContainer>
                    <GridItem xs={11} sm={12} md={6}>
                        <h1 className={classes.title}>President</h1>
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content">
                                <div>Mr. Gaurav Gupta &nbsp;&nbsp;&nbsp;(Telecom Consultant)</div>
                            </AccordionSummary>
                            <AccordionDetails>
                                <div>
                                    Flat No. A-3/148, Sector – 8, Rohini, Delhi – 110085
                                </div>
                            </AccordionDetails>
                        </Accordion>
                    </GridItem>
                    <GridItem xs={11} sm={12} md={6}>
                        <h1 className={classes.title}>Vice President</h1>
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content">
                                <div>Mr. Dilip Jha &nbsp;&nbsp;&nbsp;(Private Service)</div>
                            </AccordionSummary>
                            <AccordionDetails>
                                <div>
                                    N-9A/530, Lal Bagh, Azadpur, Delhi – 110009
                                </div>
                            </AccordionDetails>
                        </Accordion>
                    </GridItem>
                    <GridItem xs={11} sm={12} md={6}>
                        <h1 className={classes.title}>Secretary</h1>
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content">
                                <div>Mr. Anand Kumar Jha &nbsp;&nbsp;&nbsp;(Educational Service)</div>
                            </AccordionSummary>
                            <AccordionDetails>
                                <div>
                                    G-23/105, Sector – 7, Rohini, Delhi – 110085
                                </div>
                            </AccordionDetails>
                        </Accordion>
                    </GridItem>
                    <GridItem xs={11} sm={12} md={6}>
                        <h1 className={classes.title}>Treasurer</h1>
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content">
                                <div>Mr. Satish Chandra Jha &nbsp;&nbsp;&nbsp;(Admin Service)</div>
                            </AccordionSummary>
                            <AccordionDetails>
                                <div>
                                    119, Cross Road, Sant Nagar, Burari, Delhi
                                </div>
                            </AccordionDetails>
                        </Accordion>
                    </GridItem>
                </GridContainer>
            </div>
            <div className={classes.section}>
                <GridContainer>
                    <GridItem xs={11} sm={12} md={12}>
                        <h1 className={classes.title}>Executive Members</h1>
                    </GridItem>

                    <GridItem xs={11} sm={12} md={6}>
                        <h1></h1>
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content">
                                <div>Mr. Binay Mishra &nbsp;&nbsp;&nbsp;(Business)</div>
                            </AccordionSummary>
                            <AccordionDetails>
                                <div>
                                    140, Prince Anwar Shah Road, Kolkata, West Bengal
                                </div>
                            </AccordionDetails>
                        </Accordion>
                    </GridItem>
                    <GridItem xs={11} sm={12} md={6}>
                        <h1></h1>
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content">
                                <div>Ms. Sunita Devi &nbsp;&nbsp;&nbsp;(Social Worker)</div>
                            </AccordionSummary>
                            <AccordionDetails>
                                <div>
                                    129, Rajavara, Town/Vill – Rajavara, Anchal Bahedi, Distt Darbhanga - 847105, Bihar
                                </div>
                            </AccordionDetails>
                        </Accordion>
                    </GridItem>
                    <GridItem xs={11} sm={12} md={6}>
                        <h1></h1>
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content">
                                <div>Mr. Mewa Ram Rathore &nbsp;&nbsp;&nbsp;(Business (School))</div>
                            </AccordionSummary>
                            <AccordionDetails>
                                <div>
                                    B-545/12, Anand Nagar, Nithari Road, Delhi – 110086
                                </div>
                            </AccordionDetails>
                        </Accordion>
                    </GridItem>
                    <GridItem xs={11} sm={12} md={6}>
                        <h1></h1>
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content">
                                <div>Mr. Ashok Kumar Jha &nbsp;&nbsp;&nbsp;(Private Service)</div>
                            </AccordionSummary>
                            <AccordionDetails>
                                <div>
                                    Qtr No. 15, Sector 4/A, BALCO T/s, Korba, Chhatisgarh
                                </div>
                            </AccordionDetails>
                        </Accordion>
                    </GridItem>
                    <GridItem xs={11} sm={12} md={6}>
                        <h1></h1>
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content">
                                <div>Ms. Sharda Devi Goyal &nbsp;&nbsp;&nbsp;(Social Worker)</div>
                            </AccordionSummary>
                            <AccordionDetails>
                                <div>
                                    Ward No. 3, Anaaj Mandi, Surajgarh (Rural), Jhunjhunun, Rajasthan -  333029
                                </div>
                            </AccordionDetails>
                        </Accordion>
                    </GridItem>
                    <GridItem xs={11} sm={12} md={6}>
                        <h1></h1>
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content">
                                <div>Mr. Amit Sinha &nbsp;&nbsp;&nbsp;(Social Worker)</div>
                            </AccordionSummary>
                            <AccordionDetails>
                                <div>
                                    H. No. 88, Patel Nagar, Bhurkunda, Bicha, Rangarh, Jharkhand - 829106
                                </div>
                            </AccordionDetails>
                        </Accordion>
                    </GridItem>
                    <GridItem xs={11} sm={12} md={6}>
                        <h1></h1>
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content">
                                <div>Mr. Rishi Aacharya &nbsp;&nbsp;&nbsp;(Private Service)</div>
                            </AccordionSummary>
                            <AccordionDetails>
                                <div>
                                    120, Darabhanga kaloni Kaunsil Allahabad, UP
                                </div>
                            </AccordionDetails>
                        </Accordion>
                    </GridItem>
                    <GridItem xs={11} sm={12} md={6}>
                        <h1></h1>
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content">
                                <div>Mr. Bidyadhar Mohanta &nbsp;&nbsp;&nbsp;(Business (School))</div>
                            </AccordionSummary>
                            <AccordionDetails>
                                <div>
                                    Vill/Ward Jamuleibeda, Chinamalipashi, Ghatagan, Keonjhar, Odisha
                                </div>
                            </AccordionDetails>
                        </Accordion>
                    </GridItem>
                </GridContainer>
            </div>
        </>
    )
}